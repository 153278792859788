import { useEffect } from 'react'
import { Analytics } from '@genoa/analytics'
import {
  FlexAnywherePortalName,
  IntegrationType,
  isBillerFlexAnywhereLongTailPortal,
  isBillerOONPortal,
  isBillerPropertyDetails,
  isGenericPortal,
  SLCType,
} from '@genoa/domain'
import { singularSdk } from 'singular-sdk'
import { v4 as uuidV4 } from 'uuid'

import { ConnectingProperty, useEmbedFlow } from '../../hooks'
import { useAmplitude } from '../amplitude'
import { useAnalytics } from '../analytics'
import { integrationTypeToTrackingValue, productTypeToTrackingValue } from './mappers'
import { useAggregateUserInformation } from './use-aggregate-user-information'
import { getAllMarketingCampaignData } from './use-marketing-campaign-data'
import { filterEmptyValues, mapValuesToHashedSha256 } from './util'

const { Events: E, Screens: S } = Analytics

type BillConnectedEventProperties = {
  propName: string
  pmcName: string
  integrationType: string
  currentTier?: string
  qualifiedTier?: string
}

const resolveBillConnectedEventProperties = (property: ConnectingProperty): BillConnectedEventProperties => {
  if (isBillerPropertyDetails(property)) {
    const {
      name: propName,
      pmc_name: pmcName,
      integration_type: integrationType,
      billing_integration_type: billingIntegrationType,
      current_tier: currentTier,
      qualified_tier: qualifiedTier,
    } = property

    return {
      propName,
      pmcName,
      integrationType: integrationTypeToTrackingValue(integrationType, billingIntegrationType),
      ...(currentTier !== null && { currentTier }),
      ...(qualifiedTier !== null && { qualifiedTier }),
    }
  } else {
    const { display_name, portal_name } = property
    if (isBillerFlexAnywhereLongTailPortal(property)) {
      return {
        propName: display_name,
        pmcName: portal_name || FlexAnywherePortalName.FLEX_ANYWHERE,
        integrationType: IntegrationType.FLEX_ANYWHERE,
      }
    } else if (isBillerOONPortal(property)) {
      return {
        propName: display_name,
        pmcName: portal_name,
        integrationType: IntegrationType.FLEX_ANYWHERE,
      }
    } else if (isGenericPortal(property)) {
      return {
        propName: display_name,
        pmcName: portal_name,
        integrationType: IntegrationType.PORTAL,
      }
    }
  }

  return {
    propName: '',
    pmcName: '',
    integrationType: '',
  }
}

/**
 * hook providing an easy getter/setter interface to writing and accessing JSON objects to localStorage
 */
const useLocalStorageEventProperties = () => {
  const KEY = 'enhanced_shared_evt_props'
  const get = () => {
    const readValue = localStorage.getItem(KEY)
    if (readValue === undefined || readValue === null) {
      return {}
    }
    // safe parse JSON string read from Local Storage
    try {
      return JSON.parse(readValue)
    } catch (error) {
      localStorage.removeItem(KEY)
      return {}
    }
  }

  const set = (properties?: Record<string, string>) => {
    if (properties !== undefined) {
      localStorage.setItem(KEY, JSON.stringify(properties))
    } else {
      localStorage.removeItem(KEY)
    }
    return properties
  }

  return { get, set }
}

const useWrappedTrackingMethods = () => {
  const {
    enhancedTracking: {
      isEnabled: isEnhancedTrackingEnabled,
      addAndGetAllSharedEventProperties: _addAndGetAllSharedEventProperties,
      getAllSharedEventProperties,
    },
  } = useAmplitude()
  const embedFlow = useEmbedFlow()
  const aggregateUserInformation = useAggregateUserInformation()

  // wrap addAndGetAllSharedEventProperties function from useAmplitude() hook to persist in localStorage
  const { get: lsGet, set: lsSet } = useLocalStorageEventProperties()
  const addAndGetAllSharedEventProperties = (properties: Record<string, string>) => {
    return lsSet(
      _addAndGetAllSharedEventProperties({
        ...lsGet(),
        ...filterEmptyValues(properties),
      })
    )
  }

  // wrap logEvent function from useAnalytics() hook to only pass events thru if Enhanced Tracking is enabled
  const { logEvent: _logEvent, logScreenView } = useAnalytics()

  const logEvent = (name: Analytics.Events, data?: any) => {
    if (isEnhancedTrackingEnabled()) {
      // only pass the event through only if Enhanced Tracking is enabled
      const enhancedData = {
        ...data,
        platform: 'webapp',
        // [MRTCH-168] flex_touchpoint_id is a UUID V4 string that uniquely identifies the same user touchpoint
        // across our various tracking platforms: Amplitude, GTM and Singular.
        flex_touchpoint_id: uuidV4(),
      }

      // fire to Amplitude and Google Tag Manager
      _logEvent(name, enhancedData)

      // also fire the same event to Singular
      singularSdk.event(name, enhancedData)
    }
  }

  // persist marketing data, embed flow information and aggregate user information as shared event properties,
  // once for this hook use. this useEffect logic essentially runs everytime someone calls useEnhancedTracking()...
  // to ensure we respect the newest set of values that were learned and stored from the CURRENT user session and
  // avoiding overwrites, we filter out keys from the aggregated user information to only those that aren't already
  // discovered with the strippedAggregateUserInformation object
  useEffect(() => {
    const currentSharedEventPropertyKeysDiscovered = new Set(Object.keys(getAllSharedEventProperties()))
    const strippedAggregateUserInformation = Object.fromEntries(
      Object.entries(aggregateUserInformation).filter(([key, _]) => !currentSharedEventPropertyKeysDiscovered.has(key))
    )

    addAndGetAllSharedEventProperties({
      ...getAllMarketingCampaignData(),
      ...strippedAggregateUserInformation,
      embedFlow,
    })
  }, [])

  return {
    logEvent,
    logScreenView,
    getAllSharedEventProperties,
    addAndGetAllSharedEventProperties,
  }
}

export const useEnhancedTracking = () => {
  const { logEvent, logScreenView, getAllSharedEventProperties, addAndGetAllSharedEventProperties } =
    useWrappedTrackingMethods()

  const trackPhoneConsolidationScreenView = () => {
    logScreenView(S.SIGNIN_PHONE_NUMBER, {
      ...getAllSharedEventProperties(),
      consolidated: true,
    })
  }

  const trackPhoneVerified = (customerId: string | undefined) => {
    logEvent(
      E.PHONE_VERIFIED,
      addAndGetAllSharedEventProperties({
        ...(customerId !== undefined && { customerId }),
      })
    )
  }

  const trackAccountCreated = (firstName: string, lastName: string, email: string) => {
    logEvent(E.ACCOUNT_CREATED, addAndGetAllSharedEventProperties(mapValuesToHashedSha256({ email })))
  }

  const trackBillConnected = (connectedProperty: ConnectingProperty) => {
    logEvent(
      E.BILL_CONNECTED,
      addAndGetAllSharedEventProperties(resolveBillConnectedEventProperties(connectedProperty))
    )
  }

  const trackUnderwritingRequested = (productType?: SLCType, rentAmount?: string) => {
    logEvent(
      E.UNDERWRITING_REQUESTED,
      addAndGetAllSharedEventProperties({
        productType: productTypeToTrackingValue(productType),
        ...(rentAmount !== undefined && { rentAmount }),
      })
    )
  }

  const trackClickedContinueOnLoadApprovalModal = (rentAmount?: string) => {
    logEvent(
      E.CUSTOMER_ACCEPTED_LOAN,
      addAndGetAllSharedEventProperties({
        ...(rentAmount !== undefined && { rentAmount }),
      })
    )
  }

  const trackPaymentConnected = (brand?: string) => {
    logEvent(
      E.PAYMENT_CONNECTED,
      addAndGetAllSharedEventProperties({
        ...(brand !== undefined && { cardNetwork: brand }),
      })
    )
  }

  const trackUserSignUpCompleted = () => {
    logEvent(E.SIGNUP_COMPLETED, getAllSharedEventProperties())
  }

  return {
    trackPhoneConsolidationScreenView,
    trackPhoneVerified,
    trackAccountCreated,
    trackBillConnected,
    trackUnderwritingRequested,
    trackClickedContinueOnLoadApprovalModal,
    trackPaymentConnected,
    trackUserSignUpCompleted,
  }
}
