import { configureRootFlexApi } from '@genoa/state-management'
import { getAuth } from 'firebase/auth'

import { Config } from '../providers/config'
import { loggerV2 } from '../providers/logger'

const flexApi = configureRootFlexApi({
  baseUrl: Config.FLEX_2_API,
  platform: 'web',
  version: Config.VITE_BUILD_VERSION,
  versionName: `web-${Config.ENVIRONMENT}-${Config.VITE_BUILD_VERSION}`,
  getAuthToken: async () => {
    try {
      return getAuth().currentUser?.getIdToken()
    } catch (error: any) {
      loggerV2.warn('Error while getting getAuthToken', error?.message)
    }
  },
  onHttpError({ endpoint, error }) {
    loggerV2.error(`${endpoint} failed with HTTP error`, undefined, {
      error,
      endpoint,
    })
  },
  onFlexApiError({ endpoint, error: { code, message }, requestId }) {
    loggerV2.error(`${endpoint} failed with Flex API error`, undefined, {
      code,
      message,
      requestId,
      endpoint,
    })
  },
  onSuccess({ endpoint, requestId }) {
    loggerV2.info(`${endpoint} request succeeded`, undefined, { requestId, endpoint })
  },
  handleAuthTokenRefresh: async () => {
    try {
      return getAuth().currentUser?.getIdToken(true)
    } catch (error: any) {
      loggerV2.warn('Error while getting handleAuthTokenRefresh', error?.message)
    }
  },
})

export const {
  rootApi,
  account: {
    useSubmitTermsAcceptLinkMutation,
    useVerifyEmailLinkMutation,
    useGetAutopilotEligibilityQuery,
    useGetSLCStatusQuery,
    useGetAccountQuery,
    useLazyGetAccountQuery,
    useEnableSimplifiedPaymentsMutation,
    useDisableSimplifiedPaymentsMutation,
    useSetupAutopilotMutation,
    useLazyGetPaymentScheduleQuery,
    useUpsertUserAccountMutation,
    useAutopilotSetupMutation,
    useGetCreditBuilderConversionEligibilityQuery,
    useLazyGetCreditBuilderConversionEligibilityQuery,
    useConvertOfferToCreditBuilderMutation,
    useGenerateAdaTokenQuery,
  },
  payment: { useGetPaymentStatusQuery, useGetTransactionDetailsQuery },
  biller: {
    useLazyGetResidentPrefillQuery,
    useLazyGetPropertyUnitQuery,
    useGetGenericPortalsQuery,
    useGetFlexAnywhereBillerIdQuery,
    useLazyGetFlexAnywhereBillerIdQuery,
    useLazyGetResidentLookupQuery,
  },
  bill: {
    useBillPayNowMutation,
    useBillPayNowSubmitMutation,
    useCancelBillMutation,
    useGetBillAmountQuery,
    useGetBillStatusQuery,
    useGetDdaDetailsQuery,
    useGetCancelBillEligibilityQuery,
    useSelfPayConfirmPaymentMutation,
    useEmbedPayNowEligibilityQuery,
    useLazyEmbedPayNowEligibilityQuery,
  },
  wallet: { useGetCardsQuery, useSetupCardMutation, useAddCardMutation, useLazyGetCardsQuery },
} = flexApi
